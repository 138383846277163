import { useState } from "react";
import { DocumentRequiredContainer } from "../../components/documents-required";
import { useAppStore } from "../../../../core/appStore";
import { useFiles } from "../../../../services/api";
import { Spinner } from "../../../common";
import { FileCard } from "../../components/file-card";
import { FileUploader } from "../../components/file-upload";

export const KybUploadstemplate = () => {
  const { user } = useAppStore();
  const {
    data: files,
    isLoading,
    isFetching,
    refetch,
    isSuccess,
  } = useFiles(user?.id);
  const [docsSteps, setDocsSteps] = useState([
    {
      id: 1,
      title: "Director Passport Copy (Passport, Driving licence)",
      type: "passport",
      label: "Passport/Driving licence",
    },
    {
      id: 2,
      title: "Director Utility Bill",
      type: "bill",
      label: "Utility Bill",
    },
    {
      id: 3,
      title: "Company Trade Licenses/Registration Certificate",
      type: "register",
      label: "Trade Licenses/Registration Certificate",
    },
    {
      id: 4,
      title: "Last 3 Bank Statements",
      type: "statement",
      label: "Bank Statements",
    },
  ]);

  const checkExistingFile = (file_name: string) => {
    const index = files?.map((e: any) => e.file?.type).indexOf(file_name);
    if (index > -1) {
      return files[index];
    }
    return null;
  };

  const ifRejected = (doc: any) => {
    const index = files
      ?.map((e: any) => e.file.state == "rejected" && e.file?.type)
      .indexOf(doc.type);
    if (index > -1) {
      return files[index];
    }
    return null;
  };

  const ifApproved = (doc: any) => {
    const index = files
      ?.map((e: any) => e.file.state == "approved" && e.file?.type)
      .indexOf(doc.type);
    if (index > -1) {
      return files[index];
    }
    return null;
  };

  return (
    <>
      {isLoading ? (
        <div className=" w-full h-32 flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        isSuccess &&
        files && (
          <div className="flex w-full max-w-[440px] flex-col items-center gap-y-6">
            <div className="font-bold text-neutral-900 xs:text-4xl text-2xl">
              Upload your documents
            </div>
            <DocumentRequiredContainer
              docs={docsSteps}
              current_step={files.length}
              ifExists={(type: string) => checkExistingFile(type)}
              ifRejected={(file: any) => ifRejected(file)}
              ifApproved={(file: any) => ifApproved(file)}
            />

            {docsSteps.map((doc: any, index: number) => (
              <div key={doc.id} className=" w-full">
                {checkExistingFile(doc.type) ? (
                  <FileCard
                    refetch={refetch}
                    docFile={checkExistingFile(doc.type).file}
                    url={checkExistingFile(doc.type).url}
                    title={doc.label}
                  />
                ) : user ? (
                  <FileUploader
                    user={user}
                    doc={doc}
                    files_length={files?.length}
                    refetch={refetch}
                  />
                ) : null}
              </div>
            ))}
          </div>
        )
      )}
    </>
  );
};
