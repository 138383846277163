import { Link } from "react-router-dom";
import { InformationCard } from "../../modules/home/components/information-card";
import { useAppStore } from "../../core/appStore";

export const Home = () => {
  const { user } = useAppStore();
  return (
    <div className=" max-w-[1700px] ">
      <div className=" text-2xl md:text-4xl font-bold">
        Get started, {user?.name}!
      </div>
      <div className=" lg:max-w-[1000px] lg:mx-auto mt-4 md:mt-8 w-full h-[100px] xs:h-[170px] relative flex flex-col p-2 md:py-5 items-center justify-center xs:justify-start dashboard-bg rounded-xl overflow-hidden">
        <div className=" text-sm md:text-lg text-primary-500">Go Global</div>
        <div className="text-white text-base xs:text-lg lg:text-3xl">
          Payout around the world
        </div>
      </div>

      <div className="w-full mt-2 md:mt-6 flex justify-center">
        <InformationCard />
      </div>
    </div>
  );
};
