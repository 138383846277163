import { client } from "../../global/apiClient";
import { getItem } from "../../../core/storage";
import { useQuery } from "@tanstack/react-query";
import { setUserFct } from "../../../core/appStore";

const getLoggedUser = async () => {
  const { data } = await client.get(`user/session`, {
    timeout: 2000, // since it can be heavy too
    headers: {
      Authorization: `Bearer ${getItem("auth")}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  setUserFct(data);
  return data as any;
};

export function useLoggedUser() {
  return useQuery({ queryKey: ["get-logged-user"], queryFn: getLoggedUser });
}
