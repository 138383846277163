export const countryList = [
  { value: "Afghanistan" },
  { value: "Albania" },
  { value: "Algeria" },
  { value: "American Samoa" },
  { value: "Andorra" },
  { value: "Angola" },
  { value: "Anguilla" },
  { value: "Antarctica" },
  { value: "Antigua and Barbuda" },
  { value: "Argentina" },
  { value: "Armenia" },
  { value: "Aruba" },
  { value: "Australia" },
  { value: "Austria" },
  { value: "Azerbaijan" },
  { value: "The Bahamas " },
  { value: "Bahrain" },
  { value: "Bangladesh" },
  { value: "Barbados" },
  { value: "Belarus" },
  { value: "Belgium" },
  { value: "Belize" },
  { value: "Benin" },
  { value: "Bermuda" },
  { value: "Bhutan" },
  { value: "Bolivia (Plurinational State of)" },
  { value: "Bonaire, Sint Eustatius and Saba" },
  { value: "Bosnia and Herzegovina" },
  { value: "Botswana" },
  { value: "Bouvet Island" },
  { value: "Brazil" },
  { value: "The British Indian Ocean Territory " },
  { value: "Brunei Darussalam" },
  { value: "Bulgaria" },
  { value: "Burkina Faso" },
  { value: "Burundi" },
  { value: "Cabo Verde" },
  { value: "Cambodia" },
  { value: "Cameroon" },
  { value: "Canada" },
  { value: "The Cayman Islands " },
  { value: "The Central African Republic " },
  { value: "Chad" },
  { value: "Chile" },
  { value: "China" },
  { value: "Christmas Island" },
  { value: "The Cocos (Keeling) Islands " },
  { value: "Colombia" },
  { value: "The Comoros " },
  { value: "the Democratic Republic of Congo " },
  { value: "The Congo " },
  { value: "The Cook Islands " },
  { value: "Costa Rica" },
  { value: "Croatia" },
  { value: "Cuba" },
  { value: "Curaçao" },
  { value: "Cyprus" },
  { value: "Czechia" },
  { value: "Côte d'Ivoire" },
  { value: "Denmark" },
  { value: "Djibouti" },
  { value: "Dominica" },
  { value: "The Dominican Republic " },
  { value: "Ecuador" },
  { value: "Egypt" },
  { value: "El Salvador" },
  { value: "Equatorial Guinea" },
  { value: "Eritrea" },
  { value: "Estonia" },
  { value: "Eswatini" },
  { value: "Ethiopia" },
  { value: "The Falkland Islands  [Malvinas]" },
  { value: "The Faroe Islands " },
  { value: "Fiji" },
  { value: "Finland" },
  { value: "France" },
  { value: "French Guiana" },
  { value: "French Polynesia" },
  { value: "The French Southern Territories " },
  { value: "Gabon" },
  { value: "The Gambia " },
  { value: "Georgia" },
  { value: "Germany" },
  { value: "Ghana" },
  { value: "Gibraltar" },
  { value: "Greece" },
  { value: "Greenland" },
  { value: "Grenada" },
  { value: "Guadeloupe" },
  { value: "Guam" },
  { value: "Guatemala" },
  { value: "Guernsey" },
  { value: "Guinea" },
  { value: "Guinea-Bissau" },
  { value: "Guyana" },
  { value: "Haiti" },
  { value: "Heard Island and McDonald Islands" },
  { value: "The Holy See " },
  { value: "Honduras" },
  { value: "Hong Kong" },
  { value: "Hungary" },
  { value: "Iceland" },
  { value: "India" },
  { value: "Indonesia" },
  { value: "Iran (Islamic Republic of)" },
  { value: "Iraq" },
  { value: "Ireland" },
  { value: "Isle of Man" },
  { value: "Israel" },
  { value: "Italy" },
  { value: "Jamaica" },
  { value: "Japan" },
  { value: "Jersey" },
  { value: "Jordan" },
  { value: "Kazakhstan" },
  { value: "Kenya" },
  { value: "Kiribati" },
  { value: "Democratic People's Republic of Korea " },
  { value: "the Republic of Korea " },
  { value: "Kuwait" },
  { value: "Kyrgyzstan" },
  { value: "The Lao People's Democratic Republic " },
  { value: "Latvia" },
  { value: "Lebanon" },
  { value: "Lesotho" },
  { value: "Liberia" },
  { value: "Libya" },
  { value: "Liechtenstein" },
  { value: "Lithuania" },
  { value: "Luxembourg" },
  { value: "Macao" },
  { value: "Madagascar" },
  { value: "Malawi" },
  { value: "Malaysia" },
  { value: "Maldives" },
  { value: "Mali" },
  { value: "Malta" },
  { value: "The Marshall Islands " },
  { value: "Martinique" },
  { value: "Mauritania" },
  { value: "Mauritius" },
  { value: "Mayotte" },
  { value: "Mexico" },
  { value: "Micronesia (Federated States of)" },
  { value: "the Republic of Moldova " },
  { value: "Monaco" },
  { value: "Mongolia" },
  { value: "Montenegro" },
  { value: "Montserrat" },
  { value: "Morocco" },
  { value: "Mozambique" },
  { value: "Myanmar" },
  { value: "Namibia" },
  { value: "Nauru" },
  { value: "Nepal" },
  { value: "The Netherlands " },
  { value: "New Caledonia" },
  { value: "New Zealand" },
  { value: "Nicaragua" },
  { value: "The Niger " },
  { value: "Nigeria" },
  { value: "Niue" },
  { value: "Norfolk Island" },
  { value: "The Northern Mariana Islands " },
  { value: "Norway" },
  { value: "Oman" },
  { value: "Pakistan" },
  { value: "Palau" },
  { value: "Palestine, State of" },
  { value: "Panama" },
  { value: "Papua New Guinea" },
  { value: "Paraguay" },
  { value: "Peru" },
  { value: "The Philippines " },
  { value: "Pitcairn" },
  { value: "Poland" },
  { value: "Portugal" },
  { value: "Puerto Rico" },
  { value: "Qatar" },
  { value: "Republic of North Macedonia" },
  { value: "Romania" },
  { value: "The Russian Federation " },
  { value: "Rwanda" },
  { value: "Réunion" },
  { value: "Saint Barthélemy" },
  { value: "Saint Helena, Ascension and Tristan da Cunha" },
  { value: "Saint Kitts and Nevis" },
  { value: "Saint Lucia" },
  { value: "Saint Martin (French part)" },
  { value: "Saint Pierre and Miquelon" },
  { value: "Saint Vincent and the Grenadines" },
  { value: "Samoa" },
  { value: "San Marino" },
  { value: "Sao Tome and Principe" },
  { value: "Saudi Arabia" },
  { value: "Senegal" },
  { value: "Serbia" },
  { value: "Seychelles" },
  { value: "Sierra Leone" },
  { value: "Singapore" },
  { value: "Sint Maarten (Dutch part)" },
  { value: "Slovakia" },
  { value: "Slovenia" },
  { value: "Solomon Islands" },
  { value: "Somalia" },
  { value: "South Africa" },
  { value: "South Georgia and the South Sandwich Islands" },
  { value: "South Sudan" },
  { value: "Spain" },
  { value: "Sri Lanka" },
  { value: "The Sudan " },
  { value: "Suriname" },
  { value: "Svalbard and Jan Mayen" },
  { value: "Sweden" },
  { value: "Switzerland" },
  { value: "Syrian Arab Republic" },
  { value: "Taiwan" },
  { value: "Tajikistan" },
  { value: "Tanzania, United Republic of" },
  { value: "Thailand" },
  { value: "Timor-Leste" },
  { value: "Togo" },
  { value: "Tokelau" },
  { value: "Tonga" },
  { value: "Trinidad and Tobago" },
  { value: "Tunisia" },
  { value: "Turkey" },
  { value: "Turkmenistan" },
  { value: "The Turks and Caicos Islands " },
  { value: "Tuvalu" },
  { value: "Uganda" },
  { value: "Ukraine" },
  { value: "The United Arab Emirates " },
  { value: "The United Kingdom of Great Britain and Northern Ireland " },
  { value: "The United States Minor Outlying Islands " },
  { value: "The United States of America " },
  { value: "Uruguay" },
  { value: "Uzbekistan" },
  { value: "Vanuatu" },
  { value: "Venezuela (Bolivarian Republic of)" },
  { value: "Viet Nam" },
  { value: "Virgin Islands (British)" },
  { value: "Virgin Islands (U.S.)" },
  { value: "Wallis and Futuna" },
  { value: "Western Sahara" },
  { value: "Yemen" },
  { value: "Zambia" },
  { value: "Zimbabwe" },
  "Åland Islands",
];
