import { FaCheck } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { FaAngleRight } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useMqr, useFiles } from "../../../../services/api";
import { MqrCard } from "../mqr-card";
import { StepsProgress } from "../steps-progress";
import { Steps } from "../steps";
import { useAppStore } from "../../../../core/appStore";
import { useEffect, useState } from "react";

export const InformationCard = () => {
  const { user } = useAppStore();
  const { data, isError, isPending, isSuccess } = useMqr();
  const {
    data: files,
    isError: isError_files,
    isPending: isPendingFiles,
    isSuccess: isSuccess_files,
  } = useFiles(user?.id);
  const navigate = useNavigate();
  const [signedDoc, setSignedDoc] = useState<string>("");
  const [filesApproved, setFilesApproved] = useState<number>(0);
  const [filesPending, setFilesPending] = useState<number>(0);

  useEffect(() => {
    let docs = null;
    setFilesApproved(0);
    if (isSuccess_files && files.length > 0) {
      docs = files.filter((file: any) => file.file.category == "document");
    }
    docs && docs[0] && setSignedDoc(docs[0].url);
    files &&
      files.map((file: any, index: number) => {
        if (file.file.state === "approved" && file.file.category === "kyc") {
          setFilesApproved((prev) => prev + 1);
        } else if (
          file.file.state !== "rejected" &&
          file.file.category === "kyc"
        ) {
          setFilesPending((prev) => prev + 1);
        }
      });
  }, [files, isPendingFiles]);
  return (
    <>
      <div className="flex justify-center lg:justify-between w-full gap-x-6 max-w-[860px]">
        <div className="border border-neutral-300 rounded-lg p-4 shadow shadow-[rgba(0,0,0,0.3)] flex flex-col gap-y-4 justify-between">
          {/* {isPending ? (
            <div className=" w-[480px] my-6 flex items-center justify-center">
              <Spinner />
            </div>
          ) : ( */}
          <>
            <StepsProgress
              mqr={isSuccess}
              kyb={filesApproved === 4 || user?.kyc === 1}
              offer_signed={user?.offer !== null}
            />

            {data ? (
              <MqrCard mqr={data} />
            ) : (
              <div className=" bg-neutral-100 flex w-full md:w-[440px] items-center justify-center text-neutral-900 font-semibold text-lg h-28 rounded">
                <Link to={"/account-setup/company-profile"}>Complete MQR</Link>
              </div>
            )}

            <Steps
              mqr={isSuccess}
              kyb={filesApproved === 4 || user?.kyc === 1}
              offer_signed={user?.offer !== null}
              mqr_data={data}
              filesPending={filesPending}
              offer_status={user?.offer && user?.offer.eversign_status}
              files={
                signedDoc
                  ? signedDoc
                  : user?.offer
                  ? user?.offer.sign_url
                  : null
              }
              filesApproved={filesApproved}
            />
          </>
          {/* )} */}

          <div className="flex justify-between xs:justify-end gap-x-3">
            {isSuccess && (
              <button
                onClick={() =>
                  navigate("/account-setup/company-profile", {
                    state: { mqr: data },
                  })
                }
                className=" text-sm md:px-14 px-4 xs:py-2 py-1 rounded bg-neutral-100 outline-none border-none text-neutral-600 flex items-center justify-center gap-x-2"
              >
                <FiEdit size={18} />
                <div>Edit</div>
              </button>
            )}
            {isSuccess ? (
              user?.offer !== null && (
                <button
                  // to be checked if mqr done
                  onClick={() => {
                    files.length < 4
                      ? navigate("/account-setup/kyb-uploads")
                      : navigate("/");
                  }}
                  className=" text-sm md:px-8  px-4 xs:py-2 py-1 rounded bg-primary-500 outline-none border-none text-white flex items-center justify-center gap-x-1"
                >
                  <div>{"Continue"} setup</div>
                  <FaAngleRight size={14} />
                </button>
              )
            ) : (
              <button
                onClick={() => navigate("/account-setup/company-profile")}
                className=" text-sm md:px-8  px-4 xs:py-2 py-1 rounded bg-primary-500 outline-none border-none text-white flex items-center justify-center gap-x-1"
              >
                <div>{"Start"} setup</div>
                <FaAngleRight size={14} />
              </button>
            )}
          </div>
        </div>

        <div className=" flex-1 hidden lg:block">
          <img
            src="/bgs/register-bg.png"
            className=" w-full "
            alt="login-bg.png"
          />
        </div>
      </div>
    </>
  );
};
