import { useFormik } from "formik";
import { registerSchema } from "../../validations/register-validation";
import { Recaptcha } from "../common/recaptcha";
import { FormInput, FormButton, FormCheckBox } from "../common";
import { useSignUp } from "../../services/api/auth/useSignUp";
import { PopUpContainer } from "../common/pop-up-container";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const RegisterForm = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { mutateAsync, isPending, isError } = useSignUp();
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      confirm_password: "",
      website: "",
    },
    validationSchema: registerSchema,
    onSubmit: (values) => {
      register(values);
    },
  });
  const register = async (data: any) => {
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("name", data.first_name + " " + data.last_name);
    formData.append("password", data.password);
    formData.append("password_confirmation", data.confirm_password);
    await mutateAsync(formData, {
      onSuccess: (response) => {
        navigate("/account-created");
        console.log("response from login form", response);
      },
      onError: (error) => {
        // console.log("from test test", error);
      },
    }).catch((error) => {
      if (error.status === 401) {
        setErrorMessage("An account with this email already exixts");
      } else {
        setErrorMessage("");
      }
      setShowError(true);
    });
  };
  return (
    <form className="flex flex-col gap-y-2 pb-5" onSubmit={handleSubmit}>
      <div className="flex w-full justify-between">
        <div className="w-[48%]">
          <FormInput
            handleBlur={handleBlur}
            handleChange={handleChange}
            type={"text"}
            label={"First name"}
            placeholder={"Your name"}
            id="first_name"
            value={values.first_name}
            errors={errors.first_name}
            touched={touched.first_name}
            className={" !w-11"}
            name="first_name"
          />
        </div>
        <div className="w-[48%]">
          <FormInput
            handleBlur={handleBlur}
            handleChange={handleChange}
            type={"text"}
            label={"Last name"}
            placeholder={"Your family name"}
            id="last_name"
            value={values.last_name}
            errors={errors.last_name}
            touched={touched.last_name}
            name="last_name"
          />
        </div>
      </div>
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"email"}
        label={"Email"}
        placeholder={"name@example.com"}
        id="email"
        name="email"
        value={values.email}
        errors={errors.email}
        touched={touched.email}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type="password"
        placeholder="******"
        id="password"
        name="password"
        value={values.password}
        errors={errors.password}
        touched={touched.password}
        label={"Password"}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type="password"
        placeholder="******"
        id="confirm_password"
        name="confirm_password"
        value={values.confirm_password}
        errors={errors.confirm_password}
        touched={touched.confirm_password}
        label={"Confirm password"}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type="text"
        label="Website (optional)"
        id="website"
        name="website"
        value={values.website}
        errors={errors.website}
        touched={touched.website}
        placeholder={"domain.com"}
      />
      <FormCheckBox
        value={acceptTerms}
        title="Accept terms and conditions"
        onClick={() => {
          setShowTerms(true);
          setAcceptTerms(true);
        }}
      />
      {/* <Recaptcha /> */}
      <div className="mt-2">
        <FormButton
          type={"submit"}
          title="Create account"
          isLoding={isPending}
          disabled={!isValid || !acceptTerms}
        />
      </div>
      {showTerms && (
        <PopUpContainer
          with_cancel={true}
          onCancel={() => {
            setShowTerms(false);
            setAcceptTerms(false);
          }}
          onSubmit={() => {
            setShowTerms(false);
            setAcceptTerms(true);
          }}
          submit_title="Agree"
        >
          <>
            <div className="font-bold text-neutral-900 text-lg">
              Terms content
            </div>
          </>
        </PopUpContainer>
      )}
      {showError && (
        <PopUpContainer
          onCancel={() => setShowError(false)}
          onSubmit={() => setShowError(false)}
          submit_title="Got it"
        >
          <>
            <div className="font-bold text-neutral-900 text-lg">
              Somthing went wrong
            </div>
            <div className=" text-sm text-red-600 my-1 ">{errorMessage}</div>
          </>
        </PopUpContainer>
      )}
    </form>
  );
};
